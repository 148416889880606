import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import Service from '../Pages/Service';
import Contact from '../Pages/Contact';
import Work from '../Pages/Work';
import WorkDetail from '../Pages/WorkDetail';
import About from '../Pages/About';

const Body = () => {
  return (
    <div className="body">
      <Routes>
        <Route path="/*" exact element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects" element={<Work />} />
        <Route path="/projects/:id" element={<WorkDetail />} />
      </Routes>
    </div>
  )
}

export default Body