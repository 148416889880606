import React, {useState,useEffect} from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from '../assets/images/logo_transparent.png'
const Navbar = () => {
  const [scrolled,setScrolled]=useState(false);
  const [mobNav,setMobNav]=useState(false);
    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 80 ){
          setScrolled(true);
        }
        else{
          setScrolled(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll',handleScroll)
    })
  return (
    <div className={"navbar-main " +(scrolled ? 'fixed-top px-5' : '')}>
      <div className='navbar-logo'>
        <Link to='/'>
          <img src={Logo} alt='logo' />
        </Link>
      </div>
      <div className='navbar-links d-md-block d-none'>
        <ul>
          <li>
            <NavLink end className={({isActive})=> isActive ? 'active' : '' } to='/'>Intro</NavLink>
          </li>
          <li>
            <NavLink end className={({isActive})=> isActive ? 'active' : '' } to='/about'>About</NavLink>
          </li>
          <li>
            <NavLink className={({isActive})=> isActive ? 'active' : '' } to='/services'>Services</NavLink>
          </li>
          <li>
            <NavLink className={({isActive})=> isActive ? 'active' : '' } to='/projects'>Projects</NavLink>
          </li>
          {/* <li>
            <NavLink className={({isActive})=> isActive ? 'active' : '' } to='/contact'>Contact</NavLink>
          </li> */}
        </ul>
      </div>
      <div onClick={()=> setMobNav(!mobNav)} className={"mobile-burger d-md-none " +(mobNav ? 'close' : '')}>
        <div className="burger-line"></div>
        <div className="burger-line"></div>
        <div className="burger-line"></div>
      </div>
      <div className={`mobile-menu ${!mobNav ? 'd-none' : 'active'}`}>
        <ul>
          <li>
            <NavLink end className={({isActive})=> isActive ? 'active' : '' } onClick={()=>setMobNav(false)} to='/'>Intro</NavLink>
          </li>
          <li>
            <NavLink className={({isActive})=> isActive ? 'active' : '' } onClick={()=>setMobNav(false)} to='/about'>About</NavLink>
          </li>
          <li>
            <NavLink className={({isActive})=> isActive ? 'active' : '' } onClick={()=>setMobNav(false)} to='/services'>Services</NavLink>
          </li>
          <li>
            <NavLink className={({isActive})=> isActive ? 'active' : '' } onClick={()=>setMobNav(false)} to='/projects'>Projects</NavLink>
          </li>
          {/* <li>
            <NavLink className={({isActive})=> isActive ? 'active' : '' } to='/contact'>Contact</NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default Navbar