import React from 'react';

const Service = () => {
    return (
        <div className='services'>
          <div className="section-1">
            <h1 className='mb-2 text-center d-block d-md-none mt-5 ps-3'>Our Services</h1>
            <div className="row d-none d-md-flex bg">
              <div className="col-md-4 bg-1"></div>
              <div className="col-md-4 bg-2">
                <h1 className='mb-5 mt-5 ps-3'>Our Services</h1>
              </div>
              <div className="col-md-4 bg-3"></div>
            </div>
            <div className="service-content">
              <div className="row mt-md-5 mt-2 w-100 text-center">
                <div className="col-md-4 mb-3">
                  <h4 className='mb-4'>Architecture</h4>
                  <p>Residential</p>
                  <p>Institutional</p>
                  <p>Commercial</p>
                  <p>Hospitality</p>
                  <p>Healthcare</p>
                </div>
                <div className="col-md-4 mb-3">
                  <h4 className='mb-4'>Interior Design</h4>
                  <p>Apartment</p>
                  <p>Villa</p>
                  <p>Office Space</p>
                  <p>Retail Space</p>
                </div>
                <div className="col-md-4 mb-3">
                  <h4 className='mb-4'>Project Management Consultancy</h4>
                  <p>Vendor Management</p>
                  <p>Labor Management</p>
                  <p>Quality Control Management</p>
                </div>
              </div>
            </div>
            {/* <div className="services-content w-100 d-flex flex-column justify-content-center align-items-center">
              <h1 className='mb-5 mt-5'>Our Services</h1>
              <div className="row w-100 text-center">
                <div className="col-md-4 mt">
                  <h4 className='mb-4'>Architecture</h4>
                  <p>Residential</p>
                  <p>Institutional</p>
                  <p>Commercial</p>
                  <p>Hospitality</p>
                  <p>Healthcare</p>
                </div>
                <div className="col-md-4">
                  <h4 className='mb-4'>Interior Design</h4>
                  <p>Apartment</p>
                  <p>Villa</p>
                  <p>Office Space</p>
                  <p>Retail Space</p>
                </div>
                <div className="col-md-4">
                  <h4 className='mb-4'>Project Management Consultancy</h4>
                  <p>Vendor Management</p>
                  <p>Labor Management</p>
                  <p>Quality Control Management</p>
                </div>
              </div>
            </div>
            <div className="line"></div> */}
          </div>
        </div>
    );
};

export default Service;
