import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WorkData } from '../Components/WorkData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const WorkDetail = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState({});

    useEffect(() => {
        WorkData().find((data) => {
            if (data.id == id) {
                setProject(data);
            }
        });
    }, []);

    return (
        <div className='work-detail'>
            <div className='section-1 mb-3'>
                <div className='row h-100'>
                    <div className='col-md-4 mb-5 mt-5 mt-md-0 proj-title h-md-100 d-flex justify-content-center flex-column'>
                        <h5
                            className='mb-5 back-proj'
                            onClick={() => navigate('/projects')}
                        >
                            {'<- Back to Projects'}
                        </h5>
                        <h1 className='mb-5'>{project.title}</h1>
                        <h4>{project.service}</h4>
                    </div>
                    <div className='col-md-8 proj-img h-md-100'>
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            rewind={true}                            
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className='mySwiper'
                            style={
                                {
                                    boxShadow: '0 0 10px rgb(0 0 0 / 20%)',
                                }
                            }
                        >
                            {project.img &&
                                project.img.map((img) => {
                                    return (
                                        <SwiperSlide
                                            style={{
                                                backgroundImage: `url(${img})`,
                                                minHeight: '450px',
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                            }}
                                        >
                                            {/* <img src={img} alt='' /> */}
                                        </SwiperSlide>
                                    );
                                })}
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className='row proj-attributes pt-5'>
                <div className='col-md-4 attr'>
                    {project.plotArea && (
                        <>
                            <h4>Plot Area</h4>
                            <p>{project.plotArea}</p>
                        </>
                    )}
                    {project.builtUpArea && (
                        <>
                            <h4>Built Up Area</h4>
                            <p>{project.builtUpArea}</p>
                        </>
                    )}
                    {project.designArea && (
                        <>
                            <h4>Design Area</h4>
                            <p>{project.designArea}</p>
                        </>
                    )}
                    {project.location && (
                        <>
                            <h4>Location</h4>
                            <p>{project.location}</p>
                        </>
                    )}
                    {project.status && (
                        <>
                            <h4>Status</h4>
                            <p>{project.status}</p>
                        </>
                    )}
                    {project.budget && (
                        <>
                            <h4>Budget</h4>
                            <p>{project.budget}</p>
                        </>
                    )}
                </div>
                <div className='col desc'>
                    {(project?.desc?.length !== 0) && (
                        <>
                            <h4>Description</h4>
                            {/* {
                                project?.desc?.map((desc) => {
                                    return <p>{desc}</p>
                                })
                            }                             */}
                            <p>{project.desc}</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WorkDetail;
