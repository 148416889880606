import img1 from '../assets/images/home1.jpg';
import img2 from '../assets/images/meriton.jpg';
import _51 from '../assets/images/FLAT23X40/V1.jpg';
import _52 from '../assets/images/FLAT23X40/V2.jpg';
import _53 from '../assets/images/FLAT23X40/V3.jpg';
import _54 from '../assets/images/FLAT23X40/V4.jpg';
import _55 from '../assets/images/FLAT23X40/V5.jpg';
import _56 from '../assets/images/FLAT23X40/V6.jpg';
import _57 from '../assets/images/FLAT23X40/V7.jpg';
import _58 from '../assets/images/FLAT23X40/V8.jpg';
import _41 from '../assets/images/LESS IS MORE/V1.jpg';
import _42 from '../assets/images/LESS IS MORE/V2.jpg';
import _4FF from '../assets/images/LESS IS MORE/FF.jpg';
import _4GF from '../assets/images/LESS IS MORE/GF.jpg';
import _4SF from '../assets/images/LESS IS MORE/SF.jpg';
import _4TF from '../assets/images/LESS IS MORE/TF.jpg';
import _11 from '../assets/images/MEZZANINE HOUSE/V1GF.jpg';
import _12 from '../assets/images/MEZZANINE HOUSE/V2FF.jpg';
import _13 from '../assets/images/MEZZANINE HOUSE/V3SF.jpg';
import _14 from '../assets/images/MEZZANINE HOUSE/V4TF.jpg';
import _15 from '../assets/images/MEZZANINE HOUSE/V5.png';
import _16 from '../assets/images/MEZZANINE HOUSE/V6.jpg';
import _17 from '../assets/images/MEZZANINE HOUSE/V7.jpg';
import _18 from '../assets/images/MEZZANINE HOUSE/V8.jpg';
import _19 from '../assets/images/MEZZANINE HOUSE/V9.jpg';
import _110 from '../assets/images/MEZZANINE HOUSE/V10.jpg';
import _31 from '../assets/images/MUTTRAM MANAI/V1.jpg';
import _32 from '../assets/images/MUTTRAM MANAI/V2.jpg';
import _2GF from '../assets/images/SKYGREEN HOUSE/V1GF.jpg';
import _2FF from '../assets/images/SKYGREEN HOUSE/V2FF.jpg';
import _2TF from '../assets/images/SKYGREEN HOUSE/V3TF.jpg';
import _24 from '../assets/images/SKYGREEN HOUSE/V4.jpg';
import _25 from '../assets/images/SKYGREEN HOUSE/V5.jpg';
import _26 from '../assets/images/SKYGREEN HOUSE/V6.jpg';
import _27 from '../assets/images/SKYGREEN HOUSE/V7.jpg';
import _28 from '../assets/images/SKYGREEN HOUSE/V8.jpg';
import _71 from '../assets/images/SANJEEV VILLA/V1.jpg';
import _72 from '../assets/images/SANJEEV VILLA/V2.jpg';
import _73 from '../assets/images/SANJEEV VILLA/V3.jpg';
import _74 from '../assets/images/SANJEEV VILLA/V4.jpg';
import _1Proj from '../assets/images/project images/1.jpg';
import _2Proj from '../assets/images/project images/2.jpg';
import _3Proj from '../assets/images/project images/3.jpg';
import _4Proj from '../assets/images/project images/4.jpg';
import _5Proj from '../assets/images/project images/5.jpg';
import _6Proj from '../assets/images/project images/6.jpg';
import _7Proj from '../assets/images/project images/7.jpg';
import _61 from '../assets/images/MERITON/V1.jpg';
import _62 from '../assets/images/MERITON/V2.jpg';
export function WorkData(){
    return [
        {
            id:1,
            title:'MEZZANINE HOUSE',
            service:'ARCHITECTURE / INTERIOR DESIGN',
            img:[_15,_11,_12,_13,_14,_16,_17,_18,_19,_110],
            proj_img: _1Proj,
            desc:[
                'When the client wanted a glimpse of greenery flowing into the internal space of the house with a spacious 3-car parks requirement, the concept of mezzanine came into play.',
                'An internal courtyard which sits between the levels of Living and kitchen, acts as the light source and helps to regulate the outflow of hot air.',
                'The household work movement including Kitchen, Dining, Store, Utility and Laundry is placed at the same floor level to make it easy for access.',
                'Overall, a culmination of efficient space planning, unique architectural experience and climatology was taken care of.'
            ],
            plotArea: '1735 Sq. ft.',
            builtUpArea: '3000 Sq. ft.',
            location: 'Erode',
            status: 'Completed 2020',
            budget: '₹ 80 Lakhs',
        },
        {
            id:2,
            title:'SKYGREEN HOUSE',
            service:'ARCHITECTURE / INTERIOR DESIGN',
            img:[_2GF,_2FF,_2TF,_24,_25,_26,_27,_28],
            proj_img: _2Proj,
            desc:[
                'Being a lover/practitioner of gardening, the client’s focus was keen on creating an ecosystem within his livable area.',
                'To meet his requirement, we came up with an internal garden that acts as a medium of connect between ground floor, first floor and to the sky.',
                'Green wall in the frontage is to attract birds from the surrounding suburb which in turn enhances the ecosystem.',
                '70’ long garden at the backyard conceptualizes inclusive architecture connecting living room to the garden.',
                'Vastu plays a pivotal role in the house planning.',
                'Overall, a great client to work with giving us the freedom to implement our detailing.'
            ],
            plotArea: '3080 Sq. ft.',
            builtUpArea: '4500 Sq. ft.',
            location: 'Erode',
            status: 'Completed 2022',
            budget: '₹ 1.6 Crores',
        },
        {
            id:3,
            title:'MUTTRAM MANAI',
            service:'ARCHITECTURE / INTERIOR DESIGN',
            img:[_31,_32],
            proj_img: _3Proj,
            desc: [
                'A house with 2 small courtyards lighting up the entire living and transition space, partially lighting up the Bedroom spaces. '
            ],
            plotArea: '2400 Sq. ft.',
            builtUpArea: '2200 Sq. ft.',
            location: 'MEIVAZHI SALAI, PUDUKKOTTAI ',
            status: 'Completed 2022',
            budget: '₹ 45 Lakhs',
        },
        {
            id:4,
            title:'LESS IS MORE ',
            service:'ARCHITECTURE / INTERIOR DESIGN',
            img:[_4GF,_4FF,_4SF,_4TF,_41,_42],
            proj_img: _4Proj,
            desc: [],
            plotArea: '1584 Sq. ft.',
            builtUpArea: '3860 Sq. ft.',
            location: 'Erode',
            status: 'Ongoing',
            budget: '₹ 1 Crore',
        },
        {
            id:5,
            title:'FLAT 23’X40’ ',
            service:'INTERIOR DESIGN',
            img:[
                _51,
                _52,
                _53,
                _54,
                _55,
                _56,
                _57,
                _58
            ],
            proj_img: _5Proj,
            desc: [],
            plotArea: '920 Sq. ft.',
            location: 'Banglore',
            status: 'Completed',
        },
        {
            id:6,
            title:'MERITON ',
            service:'INTERIOR DESIGN',
            img:[_61,_62],
            proj_img: _6Proj,
            desc: [],
            designArea: '1000 Sq. ft.',
            location: 'Erode',
            status: 'Ongoing',
        },
        {
            id:7,
            title:'SANJEEV VILLA',
            service:'ARCHITECTURE / INTERIOR DESIGN',
            img:[_71,_72,_73,_74],
            proj_img: _7Proj,
            desc: [],
            plotArea: '2 Acres',
            builtUpArea: '7000 Sq. ft.',
            location: 'Erode',
            status: 'Ongoing',
        },
        // {
        //     id:8,
        //     title:'KUMS RESIDENCE',
        //     service:'ARCHITECTURE / INTERIOR DESIGN (RENOVATION)',
        //     img:[img2],
        //     proj_img: img2,
        //     desc: [],
        //     builtUpArea: '4500 Sq. ft.',
        //     location: 'Erode',
        //     status: 'Completed',
        // },
        // {
        //     id:9,
        //     title:'RESIDENCE @MUTHUR ',
        //     service:'PROJECT MANAGEMENT',
        //     img:[img1],
        //     proj_img: img1,
        //     desc: [],
        //     builtUpArea: '2200 Sq. ft.',
        //     location: 'Muthur, Tiruppur DT',
        //     status: 'Ongoing',
        // },
    ]
}