import React from "react";
import Home1 from "../assets/images/home1.jpg";
import Sandhiya from "../assets/images/sandhiya-home.jpg";
import SandhiyaTeam from "../assets/images/sandhiya-team.jpg";
import YogeshTeam from "../assets/images/yogesh-team.jpg";
import Data from "../assets/homedata.json";

const About = () => {
  const images = [YogeshTeam, Sandhiya];
  return (
    <div className="about">
      <div className="section-2 pt-5">
        <div className="row d-flex justify-content-center align-items-center detail mt-3">
          <div className="col-lg-5">
            <img src={Home1} alt="" />
          </div>
          <div className="col-lg-7 ps-lg-5 mt-5 mt-lg-0">
            <h1>
              iArc <span>Studio</span>
            </h1>
            <p className="mt-1">{Data["section-2"].description}</p>
          </div>
        </div>
      </div>
      <div className="section-3 pt-5 mb-5">
        <div className="row d-flex justify-content-center align-items-center detail mt-3">
          <div className="col-lg-5 d-flex justify-content-center order-lg-5">
            <img src={SandhiyaTeam} alt="" />
          </div>
          <div className="col-lg-7 mt-5 mt-lg-0 order-1">
            <h3>
             Principal Architect
            </h3>
            <p className="name">Sandhiya R</p>
            <p>{Data["section-3"].description1}</p>
            <p>{Data["section-3"].description2}</p>
          </div>
        </div>
      </div>
      {/* <div className="section-4 pt-5">
        <div className="row detail mt-3">
          {Object.keys(Data["section-4"].teams).map((key) => {
            return (
              <div className="col-lg-3 team">
                <img src={images[key]} alt="" />
                <p>{Data["section-4"].teams[key].name}</p>
                <small>{Data["section-4"].teams[key].designation}</small>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
};

export default About;
