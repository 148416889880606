import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkData } from '../Components/WorkData';

const Work = () => {
    const navigate = useNavigate();

    return (
        <div className='works h-100'>
            <div className='row h-100 w-100'>
                <div className='col-md-7 mb-5 d-none d-md-block work-imgs'>
                    {WorkData().map((item, index) => {
                        return (
                            <img
                                id={`proj-image-${item.id}`}
                                className='w-100 h-100 rounded'
                                src={item.proj_img}
                                key={index}
                                style={{
                                    display: 'none',
                                }}
                            />
                        );
                    })}
                    <img
                        id={`proj-image`}
                        className='w-100 h-100 rounded'
                        src={WorkData()[0].proj_img}
                    />
                </div>
                <div className='col ps-md-4 work-list'>
                    <h1 className='text-dark'>Projects</h1>
                    {WorkData().map((data) => {
                        return (
                            <div
                                onClick={() => navigate(`/projects/${data.id}`)}
                                className='work pt-3'
                                onMouseOver={() => {
                                    document.getElementById(
                                        'proj-image-' + data.id
                                    ).style.display = 'block';
                                    document.getElementById(
                                      'proj-image'
                                    ).src = data.proj_img;
                                }}
                                onMouseOut={() => {
                                    document.getElementById(
                                        'proj-image-' + data.id
                                    ).style.display = 'none';                                    
                                }}
                            >
                                <h3>{data.title}</h3>
                                <p>{data.service}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Work;
