import React from 'react'
import LoaderImg from '../assets/images/iArc 2.png'

const Loader = ({Loading}) => {
  return (
    <div className={"loader p-5 p-md-0" +(Loading ? ' active' : '')}>
        <img className='p-5 p-md-0' src={LoaderImg} alt='loader' />
    </div>
  )
}

export default Loader