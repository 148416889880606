import { BrowserRouter as Router, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Navbar from './Components/Navbar';
import Body from './Components/Body';
import Footer from './Components/Footer';
import AnimatedCursor from "react-animated-cursor"
import Loader from './Components/Loader';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }
  return ComponentWithRouterProp;
}

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)
function App() {

  const [loading,setLoading]=useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000);
  },[])

  return (
    <Router>
      <ScrollToTop>
        <AnimatedCursor
          innerSize={8}
          outerSize={35}
          innerScale={1}
          outerScale={1.7}
          outerAlpha={0}
          hasBlendMode={true}
          outerStyle={{
            border: '3px solid #41464c',
          }}
          innerStyle={{
            backgroundColor: '#41464c',
          }}
          clickables={['.work','a','button']}
        />
        <Loader Loading={loading} />
        <div className="App px-3 px-md-5">
          <Navbar />
          <Body />
        </div>
        <Footer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
