import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com'

const Footer = (props) => {

  const location = useLocation();

  const [isProject,setIsProject]=useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [isSent, setIsSent] = useState(true);

  const formSubmit = (e) => {
    setIsSent(false);
    e.preventDefault();
    const form = e.target;
    setName(form.name.value);
    setEmail(form.email.value);
    setMessage(form.message.value);
    setPhone(form.phone.value);
    if (name === '' || email === '' || message === '' || phone === '') {
      alert('Please fill all the fields');
      setIsSent(true);
      return;
    }
    // phone number validation
    if (phone.length !== 10 || isNaN(phone)) {
      alert('Please enter a valid phone number');
      setIsSent(true);
      return;
    }
    const formData = new FormData(form);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      message: formData.get('message'),
      phone: formData.get('phone'),
    };
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form , process.env.REACT_APP_USER_ID)
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully');
        setEmail('');
        setName('');
        setMessage('');
        setPhone('');
        setIsSent(true);
      }, (error) => {
        console.log(error.text);
        alert('Something went wrong. Please try again later');
        setIsSent(true);
        
      });
  }

  useEffect(() => {
    if(location.pathname === '/projects'){
      setIsProject(true)
    }
    else {
      setIsProject(false)
    }
  },[location])

  return (
    <div className={`footer mt-5 p-md-5 p-3 ${isProject && 'd-none'}`}>
        <div className="row px-2 mt-3">
          <div className="col-md-6 mb-5">
            <h4 className='fw-bold mb-4'>Get In Touch</h4>
            <p>Feel free to contact us for any query or suggestion. We will be happy to help you.</p>
            <div className="d-flex">
              <p className='fw-bold'>Phone:</p>
              <p className='ms-2'>+91 7904025500</p>
            </div>
            <div className="d-flex">
              <p className='fw-bold'>Email:</p>
              <p className='ms-2'>
                <a href="mailto:" className='text-decoration-none text-dark'>sandhiya@iarcstudio.co.in</a>
              </p>
            </div>
            <div className="d-flex mt-2 text-dark">
              {/* social media icons */}
              <a href="" target='_blank'>
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://www.instagram.com/iarcstudio?r=nametag" target='_blank' className='ms-3'>
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/in/sandhiyarr/" target='_blank' className='ms-3'>
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <h4 className='fw-bold mb-4'>Contact Us</h4>
            <form onSubmit={formSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <input type="text" className='form-control rounded-0' name='name' value={name} onChange={(e)=> setName(e.target.value)} placeholder='Name' required />
                  </div>
                  <div className="mb-3">
                    <input type="email" className='form-control rounded-0' name='email' value={email} onChange={(e)=> setEmail(e.target.value)} placeholder='Email' required />
                  </div>
                  <div className="mb-3">
                    <input type="text" className='form-control rounded-0' name='phone' value={phone} onChange={(e)=> setPhone(e.target.value)} placeholder='Phone' required />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <textarea id="" cols="30" rows="4" name='message' value={message} onChange={(e)=> setMessage(e.target.value)} className='form-control rounded-0' placeholder='Message' required></textarea>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button className='btn btn-dark' disabled={!isSent} >Send</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  )
}

export default Footer