import { useRef } from 'react';
import Sandhiya from '../assets/images/sandhiya-home.jpg';
import SandhiyaTeam from '../assets/images/sandhiya-team.jpg';
import YogeshTeam from '../assets/images/yogesh-team.jpg';
import { useNavigate } from 'react-router-dom';
import { WorkData } from '../Components/WorkData';
import Bg from '../assets/images/bg-wave.jpg';


const Home = () => {
    const images = [
        SandhiyaTeam,
        YogeshTeam,
        Sandhiya
    ]
    const ref = useRef(null);

    const navigate = useNavigate()

    const handleClick = () => {
      window.scroll({top:ref.current?.offsetTop -200 ,behavior: 'smooth' });
    };

    return (
        <div className='home'>
            <div className='section-1'>
                <img src={Bg} alt="" />
                <div className="bg"></div>
                <div className="content">
                  <h1>We build, it builds us!</h1>
                  <a onClick={handleClick} className='scroll-down'>
                      <div className='mouse'>
                          <div className='wheel'></div>
                      </div>
                      <div>
                          <span className='m_scroll_arrows unu'></span>
                          <span className='m_scroll_arrows doi'></span>
                          <span className='m_scroll_arrows trei'></span>
                      </div>
                  </a>
                </div>
            </div>   
            <div ref={ref} className='section-2 py-5'>
                <h1 className='mb-5'>Recent Works</h1>
                <div className="recent-works d-flex flex-wrap justify-content-center align-items-center mb-5">
                  <div className="work">
                    <img src={WorkData()[0].proj_img} alt="work-1" />
                    <div className="work-content">
                      <h1>{WorkData()[0].title}</h1>
                      <button className="btn btn-secondary" onClick={()=>{
                        navigate(`/projects/${WorkData()[0].id}`)
                      }}>View Project</button>
                    </div>
                  </div>
                  <div className="work">
                    <img src={WorkData()[1].proj_img} alt="work-1" />
                    <div className="work-content">
                      <h1>{WorkData()[1].title}</h1>
                      <button className="btn btn-secondary" onClick={()=>{
                        navigate(`/projects/${WorkData()[0].id}`)
                      }}>View Project</button>
                    </div>
                  </div>
                  <div className="work">
                    <img src={WorkData()[4].proj_img} alt="work-1" />
                    <div className="work-content">
                      <h1>{WorkData()[4].title}</h1>
                      <button className="btn btn-secondary" onClick={()=>{
                        navigate(`/projects/${WorkData()[4].id}`)
                      }}>View Project</button>
                    </div>
                  </div>
                </div>
                <div className="works-button">
                  <button className='btn btn-secondary' onClick={() => navigate('/projects')}>View All Projects</button>
                </div>
                <div className="line"></div>
            </div>           
        </div>
    );
};

export default Home;
